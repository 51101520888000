.signin_container {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    max-width: 60%;
    margin: 30vh auto;
    /* background-color: aqua; */
    background-color: white;
    border-radius: 10px;
    height: fit-content;
    padding: 2rem;
    max-width: 400px;
    
  }
  .signin_container form {
    width: 100%;
    border: 1px solid #dfdfdf;
    padding: 4em 0;
    padding-top: 3em;
    border-radius: 15px;
    background: #fff;
    text-align: center;
  }
  .signin_container form label{
      color: #012d70;
      padding-bottom: 2em;
      /* background-color: aqua; */
      display: inline;
  }
  /* pre {
    width: 70%;
    color: #fff;
    font-size: larger;
  } */

  .signin_container p {
    color: red;
  }

  .signin_container input[type="text"], .signin_container input[type="password"]{
    padding: 0.3em;
    border-radius: 12px;
    min-width: 50%;
    max-width: 60%;
  }
  .signin_container button{
      border-radius: 12px;
      padding: 0.4em 2em;
      background: #012d70;
      font-weight: bold;
      color: white;
      cursor: pointer;
  }

  .field{
      display: inline-block;
      margin-top: 0.5em;
      width: 100%;
      /* background-color: aqua; */
  }

  @media screen and (min-width : 768px) {
      .signin_container form{
          width: 60%;
      }
      .signin_container input[type="text"], .signin_container input[type="password"]{
        font-size: medium;
      }

  }
