.bot_list{
    background: #FFFFFF;
    box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 0.5vw;
    margin: auto;
    margin-top: 2vw;
    margin-bottom: 2vw;
    width: 55vw;
}
.bot_list .manage_btn{
    background: #012D70;
    color: white;
    box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 0.2vw 0.6vw;
    font-weight: bold;
}

.bot_list td{
    padding: .3vw 0;
}

.bot_list th{
    padding: 0  0 .3vw 0;
    border-bottom: 2px solid #012D70;
}