#contact a{
    text-decoration: none;
}

#contact{
    scroll-margin-top: 160px;
}

.fa_icons{
    padding: 2px 4px;
    cursor: pointer;
    font-size: 1.8em;
    margin: 0 0.15vw;
    transition: 0.1s ease-out;
}

.fa_icons:hover{
    font-size: 2em;
    margin: -0.1em 0.05vw;
    transition: 0.1s ease-in;
}