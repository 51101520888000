#foot{
    width: 100%;
    background: white;
    text-align: center;
    padding: 10px 0;
    color: #012D70;
}
#crobot_logo{
    width: 10vw;
    /* height: 15vh; */
    min-width: 8vw !important;
    margin: auto;
    text-align: center;
    /* position: absolute; */
}

#foot p{
    font-size: 0.7em;
    font-family: verdana, sans-serif;
}