.services:hover{
    width: 21em;
    height: 19em;
    margin: 12px;
    transition: 0.1s ease-in;
}

.services{
    transition: 0.1s ease-out;
    background: #FFFFFF;
    border: 1px solid #14213D;
    box-sizing: border-box;
    box-shadow: 0px 4px 17px 1px rgba(0, 0, 0, 0.4);
    border-radius: 19px;

    width: 20em;
    height: 18em;
    display: inline-block;
    text-align: center;
    padding: 30px 20px 30px 20px;
    margin: 20px;

    vertical-align: top;
}

.services div img{
    max-width: 100%;
    max-height: 100%;
}

.services div{
    background: rgba(236, 91, 126, 0.29);
    padding: 10px;
    border-radius: 9px;
    position: relative;
    margin: auto;
    height: 18%;
    width: 18%; 
}

#services{
    text-align: center;
    /* box-sizing: border-box; */
    /* padding: 5%; */
    width: 100%;
    margin-top: 10%;
    margin: 0 auto 5% ;
    scroll-margin-top: 130px;
    text-align: center;
}