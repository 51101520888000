#count_label{
    width: 10%;
    position: absolute;
    text-align: right;
    line-height: 2vw;
    left: 5vw;
    top: 11.6vw;
    color: yellow;
}
#count_label span{
    padding: 0.5vw;
    color: white;
}
#count_label button{
    /*text-align: left;*/
    margin-top: 1vw;
    padding: 0.2vw 0.6vw;
    font-weight: bold;
    font-size: medium;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 5px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    cursor: pointer;
}

#user_img{
    border: 3px solid white;
    border-radius: 50%;
    background-color: aqua;
    width: 9vw;
}

#user_brief div, #user_brief h2{
    margin: 0;    
}
#user_brief{
    margin-bottom: 3vw;
    margin-top: 3vw;
    color: white;
}
#user_brief h2{
    color: yellow;
}
