.under-maintenance{
    width: 60%;
	padding: 3% 0;
	margin:  170px auto 0;
	text-align: center;
	background: white;
	color: black;
	box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.25);
	border-radius: 25px;
    margin-bottom: 8%;
}

.under-maintenance img{
    width: 50vw;
    height: auto;
}

.under-maintenance h2{
    margin-bottom: 5%;
    color: #012D70;
}