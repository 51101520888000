#elastic_carousel img{
    max-width: 100%;
}

#elastic_carousel{
    position: relative; 
    scroll-margin-top: 190px;
}

#elastic_carousel:hover .rec.rec.rec-arrow-left,#elastic_carousel:hover .rec.rec.rec-arrow-right{
    display: block;
}
.rec.rec.rec-arrow-left, .rec.rec.rec-arrow-right{
    position: absolute;
    z-index: 2;
    display: none;
}

.rec.rec.rec-arrow-right{
    right:0;
    transform: translate(-50%, 0);
}
.rec.rec.rec-arrow-left{
    left:0;
    transform: translate(50%, 0);
}
.rec.rec-arrow-left,
.rec.rec-arrow-right{
    
    color: white !important;
    background-color:transparent !important;
    box-shadow: none;
}
.rec.rec-arrow-left:hover,
.rec.rec-arrow-right:hover{
    
    color: white;
    background-color:gray !important;
    box-shadow: none;
}

.rec-dot_active {
    background-color: white !important;
    box-shadow: black !important;
}
.rec-dot:focus,
.rec-dot:hover {
    box-shadow: black !important;
}
.rec-dot_active:focus,
.rec-dot_active:hover {
    background-color: white !important;
    box-shadow: black !important;
}
