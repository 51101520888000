
#profile_wrapper{
    text-align: center;
    margin: auto;
    padding: 1vw 2vw;
    margin-bottom: 3vw;
    width: 50vw;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
}

#profile_wrapper h3{
    color: #012D70;
    margin-bottom: 2vw;
}
.profile_div div{
    line-height: 1vw;
    display: inline-block;
    width: 8vw;
    color: #012D70;
    font-weight: bold;
    text-align: left;
    /* background-color: aquamarine; */
}

.profile_div{
    display: inline-block;
    padding: 0 2vw;
}
.profile_div textarea{
    margin-bottom: -0.78vw;
    resize: none;
}


#edit_profile{
    background: #012D70;
    color: white;
    box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 0.2vw 0.6vw;
    font-weight: bold;

    margin-top: 2vw;
    cursor: pointer;
}