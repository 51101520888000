#contact{
    color: white;
    line-height: 1.5;
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 2.5% 0;
}

#about_us{
    width: 68.5%;
    scroll-margin-top: 160px;
    margin: auto;
    text-align: center;
    padding: 2.5% 0;
}

#about_us div{
    width: 100%;
    box-sizing: border-box;
    background: white;
    text-align: center;
    margin: auto;
    /* margin-top: 5%; */
    box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 3% 6%;
}

#about_us ol{
    display: inline-block;
    text-align: left;
}