#subscription_container{
    width: 10%;
    position: absolute;
    right: 5vw;
    top: 150px;
    color: white;
}
#subscription_text{
    font-size: xx-large;
}
#subscription_text span{
    color: #32CD32;
}
#days_left{
    font-size: small;
}

#below_subscription{
    margin-top: 1vw;
    font-size: large;
}
#subscription_container div a{
    color: red;
    text-decoration: none;
    font-size: medium;
}
