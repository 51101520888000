.titles{
    background: #012D70;
    padding: 0 20px;
}
.yellow_line{
    color: #FFF73E;
    margin: auto;
    font-size: 1.3em;
    background: #012D70;
    vertical-align: middle;
    border-bottom: 3px solid #FFF73E;
    width: 82%;
    text-align: center;
    line-height: 0em;
    margin-bottom: 2vw;
    margin-top: 4vw;		
}