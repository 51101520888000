.navbar{
    width: 100%;
    height: auto;
    color: #012D70;
    position: sticky;
    text-align: center;
    /* align-items: center; */
    font-weight: bold;
    background: white;
    box-shadow: 0px 4px 17px 4px rgba(0, 0, 0, 0.65);
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
}

/* #login_btn{
right: 0;
padding: 0.5em 2em;
top: 0.8em;
margin-right: 3em;
position: absolute;
border-radius: 15px;
color: white;
background-color: #012D70;
font-weight: bold;
font-size: 0.9em;
} */
#header_h3{
    display: inline-block;
    margin-left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#header_ct{
    width: 4vw;
    padding: 0 1vw;
    
}
.avatar-btn-container{
    width: 120px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.login-nav{
    text-decoration: none;

    border-radius: 15px;
    color: white;
    background-color: #012D70;
    font-weight: bold;
    font-size: 0.9em;
    }
#login_info{
    display: flex;
    align-items: center;
    cursor: pointer;
}
#userToggleMenu, #sideMenu{
    /* position: absolute; */
    
    right: -20vw;
    top: 105px;
    background-color: grey;
    z-index: 10;
    width: 10vw;
    font-weight: bold;
    transition: 0.25s ease-out;

    position: fixed;
    border-radius: 15px;
    padding: 0.6vw 0.1vw;
    background: rgba(215, 217, 239, 0.86);
    box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(13px);
    /* display: none; */
}
#userToggleMenu div, #sideMenu div{
    padding: 0 0.8vw;
    cursor: pointer;
}
#sideMenu{
    /* position: absolute; */
    width: 14vw;
    left: -20vw;
}
/*
position:"absolute", right:"1vw", top:"4vw", backgroundColor:"gray", display:"none"
*/

hr{
    margin: 0;
    background-color: #012D70;
    height: 0px;
    border-top:0.1px solid #012D70;
    border-bottom: 0;
    margin-top: 0.4vw;
    margin-bottom: 0.1vw;
}

.intLink{
    text-decoration: none;
    color: #012D70;
    font-weight: bold;
    display: block;
}

#three_bars{
    left: 2%;
    padding: 0;
    font-size: 1.6vw;
    top:calc(50% - 10px);
    vertical-align: middle;
    position: absolute;
    cursor: pointer;
}